import React, { Fragment, useEffect, useContext } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Layout from "layout"
import Container from "layout/Container"

import styles from "./utils/bookingComplete.module.scss"
import { useBookingCompleteImages } from "./hooks/useBookingCompleteImages"
import { MentalHealthContext } from "../../MentalHealthContext/MentalHealthContext"

const Completed = (props) => {
  const { pageContext } = props
  const { module, content, nextPath } = pageContext
  const data = useBookingCompleteImages()

  const { mentalHealthDispatch } = useContext(MentalHealthContext)

  useEffect(() => {
    const restartDetails = async () => {
      await mentalHealthDispatch({ type: "RESET_DETAILS" })
    }
    restartDetails()
  }, [mentalHealthDispatch])

  return (
    <Layout
      title={module.title || "Thank you"}
      seoTitle={module.seoTitle}
      isPrivate
      pageContext={pageContext}
    >
      <Container isCentered>
        <div className={classNames("columns", styles["column"])}>
          {content.icon && (
            <div className="column is-4-desktop is-12-mobile">
              <figure className={classNames("image mx-3")}>
                <Img
                  fluid={data[content.icon].childImageSharp.fluid}
                  alt={`${module.name} Complete`}
                  className={styles["image"]}
                />
              </figure>
            </div>
          )}
          <div className="column">
            <Fragment>
              <p className="my-1 mb-2">
                Our mental health coordinator will contact you through SMS or
                email. If you don’t hear from us within the next working day, or
                should you wish to change some of your answers, please email us
                at{" "}
                <a
                  href={`mailto:${process.env.GATSBY_AWS_SES_EMAIL_TO}`}
                  className="has-text-weight-bold"
                >
                  {process.env.GATSBY_AWS_SES_EMAIL_TO}
                </a>
                .
              </p>
            </Fragment>
          </div>
        </div>
        <center>
          <Link to={nextPath} className="mt-2 button is-primary is-medium">
            Finish
          </Link>
        </center>
      </Container>
    </Layout>
  )
}

export default Completed
